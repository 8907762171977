import { ClearanceField } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import React from "react";
import { ClearanceStatus } from "src/enums";
import { useKey, useTdls } from "src/hooks";
import {
  clearanceSelector,
  clearanceStatusSelector,
  isReadOnlySelector,
  selectedAircraftIdSelector,
  setClearanceStatus,
  setSelectedAircraftId,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/tdls/flightPlan";
import { formatRoute } from "src/utils";
import ClearanceFieldSelect from "./ClearanceFieldSelect";

function ClearanceEditor() {
  const clearance = useAppSelector(clearanceSelector)!;
  const clearanceStatus = useAppSelector(clearanceStatusSelector);
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const dispatch = useAppDispatch();

  const { dumpFlightPlan, sendClearance, getFlightPlan, cycleSelectedClearanceField } = useTdls();

  const flightPlan = getFlightPlan(useAppSelector(selectedAircraftIdSelector)!);
  const isCleared = !!flightPlan.clearance;

  useKey("Tab", () => cycleSelectedClearanceField(true), { aircraftIsSelected: true });
  useKey("`", () => cycleSelectedClearanceField(false), { aircraftIsSelected: true });
  useKey("RightArrow", (e) => e.preventDefault(), { aircraftIsSelected: true });
  useKey("LeftArrow", (e) => e.preventDefault(), { aircraftIsSelected: true });
  useKey("F4", () => !isReadOnly && dumpFlightPlan(flightPlan.aircraftId), { aircraftIsSelected: true });
  useKey(
    "F10",
    () => {
      dispatch(setSelectedAircraftId(undefined));
      dispatch(setClearanceStatus(undefined));
    },
    { aircraftIsSelected: true },
  );
  useKey(
    "F12",
    () => !(clearanceStatus === ClearanceStatus.MandatoryFieldNotSet || isCleared || isReadOnly) && sendClearance(flightPlan.aircraftId, clearance),
    { aircraftIsSelected: true },
  );

  return (
    <S.FlightPlan>
      <S.FlightPlanRow1>
        <S.FlightPlanCol1>
          <S.FlightPlanField>{flightPlan.aircraftId}</S.FlightPlanField>
          <S.FlightPlanField $leftMargin>{(flightPlan.assignedBeaconCode ?? 0).toString().padStart(4, "0")}</S.FlightPlanField>
        </S.FlightPlanCol1>
        <S.FlightPlanRoute dangerouslySetInnerHTML={{ __html: formatRoute(flightPlan) }} />
      </S.FlightPlanRow1>
      <S.FlightPlanRow2>
        <S.FlightPlanCol1>
          <S.FlightPlanField>{`${flightPlan.aircraftType}/${flightPlan.faaEquipmentSuffix}`}</S.FlightPlanField>
          <S.FlightPlanField $leftMargin>P{flightPlan.estimatedDepartureTime.toString().padStart(4, "0")}</S.FlightPlanField>
        </S.FlightPlanCol1>
        <S.FlightPlanCol3>RMK: {flightPlan.remarks}</S.FlightPlanCol3>
      </S.FlightPlanRow2>
      <S.FlightPlanRow>
        <S.FlightPlanCol1>
          <S.FlightPlanField>{flightPlan.cid}</S.FlightPlanField>
          <S.FlightPlanField $leftMargin>{flightPlan.altitude}</S.FlightPlanField>
        </S.FlightPlanCol1>
        <S.FlightPlanCol2>
          <ClearanceFieldSelect field={ClearanceField.Expect} />
        </S.FlightPlanCol2>
      </S.FlightPlanRow>
      <S.FlightPlanRow>
        <S.FlightPlanCol1>
          <ClearanceFieldSelect field={ClearanceField.Sid} />
          <ClearanceFieldSelect field={ClearanceField.Transition} />
        </S.FlightPlanCol1>
        <S.FlightPlanCol4>
          <ClearanceFieldSelect field={ClearanceField.Climbout} />
          <ClearanceFieldSelect field={ClearanceField.Climbvia} />
          <ClearanceFieldSelect field={ClearanceField.InitialAlt} />
        </S.FlightPlanCol4>
      </S.FlightPlanRow>
      <S.FlightPlanRow2>
        <ClearanceFieldSelect field={ClearanceField.ContactInfo} />
        <S.FlightPlanField>
          DEP FREQ
          <ClearanceFieldSelect field={ClearanceField.DepFreq} />
        </S.FlightPlanField>
      </S.FlightPlanRow2>
      <S.FlightPlanRow>
        <ClearanceFieldSelect field={ClearanceField.LocalInfo} />
      </S.FlightPlanRow>
      <S.FlightPlanFooter>
        <S.FlightPlanButton $isOnLeft onClick={() => dumpFlightPlan(flightPlan.aircraftId)} hidden={isCleared} disabled={isReadOnly}>
          Dump
        </S.FlightPlanButton>
        <S.FlightPlanButton disabled>Edit</S.FlightPlanButton>
        <S.FlightPlanButton
          onClick={() => {
            dispatch(setSelectedAircraftId(undefined));
            dispatch(setClearanceStatus(undefined));
          }}
        >
          Cancel
        </S.FlightPlanButton>
        <S.FlightPlanButton
          onClick={() => sendClearance(flightPlan.aircraftId, clearance)}
          disabled={clearanceStatus === ClearanceStatus.MandatoryFieldNotSet || isCleared || isReadOnly}
        >
          Send
        </S.FlightPlanButton>
      </S.FlightPlanFooter>
    </S.FlightPlan>
  );
}

export default ClearanceEditor;
