import { createSlice } from "@reduxjs/toolkit";
import { ClearanceField } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { AircraftList } from "src/enums";
import { getSavedOption } from "src/utils";
import { RootState } from "../store";

const DARK_MODE_LOCAL_STORAGE_NAME = "dark-mode-enabled";
const NOTIFICATION_SOUND_LOCAL_STORAGE_NAME = "notification-sound-enabled";

interface UiState {
  selectedAircraftList: AircraftList;
  highlightedAircraftId?: string;
  selectedAircraftId?: string;
  selectedClearanceField: ClearanceField;
  facilityMenuIsActive: boolean;
  darkModeIsEnabled: boolean;
  notificationSoundIsEnabled: boolean;
}

const initialState: UiState = {
  selectedAircraftList: AircraftList.Dcl,
  selectedClearanceField: ClearanceField.Sid,
  facilityMenuIsActive: false,
  darkModeIsEnabled: getSavedOption(DARK_MODE_LOCAL_STORAGE_NAME),
  notificationSoundIsEnabled: getSavedOption(NOTIFICATION_SOUND_LOCAL_STORAGE_NAME),
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSelectedAircraftList(state, action: { payload: AircraftList }) {
      state.selectedAircraftList = action.payload;
    },
    setHighlightedAircraftId(state, action: { payload: string | undefined }) {
      state.highlightedAircraftId = action.payload;
    },
    setSelectedAircraftId(state, action: { payload: string | undefined }) {
      state.selectedAircraftId = action.payload;
    },
    setSelectedClearanceField(state, action: { payload: ClearanceField }) {
      state.selectedClearanceField = action.payload;
    },
    setFacilityMenuIsActive(state, action: { payload: boolean }) {
      state.facilityMenuIsActive = action.payload;
    },
    setDarkModeIsEnabled(state, action: { payload: boolean }) {
      state.darkModeIsEnabled = action.payload;
      localStorage.setItem(DARK_MODE_LOCAL_STORAGE_NAME, action.payload.toString());
    },
    setNotificationSoundIsEnabled(state, action: { payload: boolean }) {
      state.notificationSoundIsEnabled = action.payload;
      localStorage.setItem(NOTIFICATION_SOUND_LOCAL_STORAGE_NAME, action.payload.toString());
    },
    resetUi(state) {
      state.selectedAircraftList = AircraftList.Dcl;
      state.highlightedAircraftId = undefined;
      state.selectedAircraftId = undefined;
      state.facilityMenuIsActive = false;
      state.selectedClearanceField = ClearanceField.Sid;
    },
  },
});

export const {
  setSelectedAircraftList,
  setHighlightedAircraftId,
  setSelectedAircraftId,
  setSelectedClearanceField,
  setFacilityMenuIsActive,
  setDarkModeIsEnabled,
  setNotificationSoundIsEnabled,
  resetUi,
} = uiSlice.actions;

export const selectedAircraftListSelector = (state: RootState) => state.ui.selectedAircraftList;
export const highlightedAircraftIdSelector = (state: RootState) => state.ui.highlightedAircraftId;
export const selectedAircraftIdSelector = (state: RootState) => state.ui.selectedAircraftId;
export const selectedClearanceFieldSelector = (state: RootState) => state.ui.selectedClearanceField;
export const facilityMenuIsActiveSelector = (state: RootState) => state.ui.facilityMenuIsActive;
export const darkModeIsEnabledSelector = (state: RootState) => state.ui.darkModeIsEnabled;
export const notificationSoundIsEnabledSelector = (state: RootState) => state.ui.notificationSoundIsEnabled;

export default uiSlice.reducer;
