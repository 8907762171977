import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { facilityIdSelector, facilityIdsSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/login";

interface FacilitiesTableProps {
  onSelectFacility?: (facilityId: string) => void;
}

function FacilitiesTable({ onSelectFacility }: Readonly<FacilitiesTableProps>) {
  const facilityIds = useAppSelector(facilityIdsSelector);
  const currentFacilityId = useAppSelector(facilityIdSelector);
  const navigate = useNavigate();

  const handleSelect = (selectedFacilityId: string) => {
    if (selectedFacilityId === currentFacilityId) {
      return;
    }
    navigate(`/${selectedFacilityId}`);
    onSelectFacility?.(selectedFacilityId);
  };

  return (
    <S.FacilitiesTable>
      <table>
        <tbody>
          {facilityIds?.length ? (
            facilityIds.map((facilityId) => (
              <S.Row key={facilityId} onClick={() => handleSelect(facilityId)} $selected={facilityId === currentFacilityId}>
                <td>
                  <S.SelectedIndicator icon={faChevronRight} />
                  {facilityId}
                </td>
              </S.Row>
            ))
          ) : (
            <S.Row $selected>
              <td>No TDLS Facility Available</td>
            </S.Row>
          )}
        </tbody>
      </table>
    </S.FacilitiesTable>
  );
}

FacilitiesTable.defaultProps = {
  onSelectFacility: undefined,
};

export default FacilitiesTable;
