import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CurrentLogin, FacilitiesTable } from "src/components/login";
import { Checkbox, LogoutButton } from "src/components/ui";
import { useKey } from "src/hooks";
import {
  darkModeIsEnabledSelector,
  facilityFilterIsEnabledSelector,
  facilityIdsSelector,
  facilityMenuIsActiveSelector,
  notificationSoundIsEnabledSelector,
  setDarkModeIsEnabled,
  setFacilityFilterIsEnabled,
  setFacilityMenuIsActive,
  setNotificationSoundIsEnabled,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/menus";
import { Hr, VersionNumber } from "src/styles/ui";
import { VERSION } from "src/utils";
import FrequenciesInput from "./FrequenciesInput";
import OverrideFrequencyInput from "./OverrideFrequencyInput";

function FacilityMenu() {
  const facilityMenuActive = useAppSelector(facilityMenuIsActiveSelector);
  const facilityIds = useAppSelector(facilityIdsSelector);
  const filterEnabled = useAppSelector(facilityFilterIsEnabledSelector);
  const darkModeEnabled = useAppSelector(darkModeIsEnabledSelector);
  const notificationSoundEnabled = useAppSelector(notificationSoundIsEnabledSelector);
  const dispatch = useAppDispatch();

  useKey("Escape", () => dispatch(setFacilityMenuIsActive(false)), { facilityMenuIsActive: true });

  if (!facilityMenuActive) {
    return undefined;
  }

  return (
    <>
      <S.Screen onClick={() => dispatch(setFacilityMenuIsActive(false))} />
      <S.FacilityMenu>
        <S.CloseButton onClick={() => dispatch(setFacilityMenuIsActive(false))}>
          <FontAwesomeIcon icon={faTimes} />
        </S.CloseButton>
        <CurrentLogin showSessionStatus showHiddenFacilities />
        <Hr />
        <FrequenciesInput />
        <Hr />
        <OverrideFrequencyInput />
        <Hr />
        {facilityIds && facilityIds.length > 1 && (
          <>
            <S.Subtitle>Change Facility:</S.Subtitle>
            <FacilitiesTable onSelectFacility={() => dispatch(setFacilityMenuIsActive(false))} />
          </>
        )}
        <Checkbox
          label="Filter staffed underlying facilities"
          checked={filterEnabled}
          onChange={(checked) => dispatch(setFacilityFilterIsEnabled(checked))}
        />
        <Checkbox label="Enable dark mode" checked={darkModeEnabled} onChange={(checked) => dispatch(setDarkModeIsEnabled(checked))} />
        <Checkbox
          label="Play notification sound"
          checked={notificationSoundEnabled}
          onChange={(checked) => dispatch(setNotificationSoundIsEnabled(checked))}
        />
        <br />
        <LogoutButton />
        <S.Version>
          <b>Version</b>
          <VersionNumber>{VERSION}</VersionNumber>
        </S.Version>
      </S.FacilityMenu>
    </>
  );
}

export default FacilityMenu;
