import { OpenPositionDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";

export default class OpenPosition {
  id: string;

  facilityId: string;

  constructor(dto: OpenPositionDto) {
    this.id = dto.id;
    this.facilityId = dto.facilityId!;
  }
}
