import styled from "styled-components";
import { COLOR_DANGER, COLOR_MENU_BACKGROUND, COLOR_SECONDARY } from "./colors";

export const Screen = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #00000050;
  position: fixed;
  top: 0;
  z-index: 99;
`;

export const FacilityMenu = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 200px;
  width: 400px;
  z-index: 100;
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${COLOR_MENU_BACKGROUND};
  box-shadow: -5px 0 40px 5px rgba(0, 0, 0, 0.5);
  color: white;
`;

export const CloseButton = styled.button`
  border: none;
  background: none !important;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5em;

  &:hover {
    cursor: pointer;
    color: lightgrey;
  }

  &:active {
    color: grey;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.span`
  font-size: "1.2em";
`;

export const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const Version = styled.span`
  position: absolute;
  bottom: 5px;
  color: #869099;
`;

export const FrequenciesInput = styled.input<{ $invalid: boolean }>`
  margin-top: 10px;
  height: 38px;
  color: white;
  background-color: inherit;
  border: 1px solid ${COLOR_SECONDARY};
  border-radius: 4px;
  padding: 0 6px;
  text-align: center;
  width: 75%;
  font-size: 1.2em;
  border-color: ${(p) => p.$invalid && COLOR_DANGER};
  text-transform: uppercase;
`;

export const OverrideFreqSelect = styled.select`
  margin-top: 10px;
  height: 38px;
  color: white;
  background-color: inherit;
  border: 1px solid ${COLOR_SECONDARY};
  border-radius: 4px;
  padding: 0 6px;
  width: 25%;
  font-size: 1.2em;
`;

export const InvalidLabel = styled.small`
  color: ${COLOR_DANGER};
`;
