import { usePrompt } from "@vatsim-vnas/js-libs/hooks";
import { cycleNextItemInArray, cyclePreviousItemInArray } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dcl, Footer, Header } from "src/components/tdls";
import { FacilityMenu } from "src/components/ui";
import { useKey, useTdls } from "src/hooks";
import { facilityIdsSelector, highlightedAircraftIdSelector, setFacility, setFacilityMenuIsActive, useAppDispatch, useAppSelector } from "src/redux";

function Tdls() {
  const facilityId = useParams().id!;
  const highlightedAircraftId = useAppSelector(highlightedAircraftIdSelector);
  const facilityIds = useAppSelector(facilityIdsSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cycleSelectedList, cycleHighlightedAircraft, selectAircraft } = useTdls();
  usePrompt();

  useEffect(() => {
    dispatch(setFacility(facilityId));
  }, [facilityId]);

  function cycleFacility(next: boolean) {
    if (!facilityIds || facilityIds.length < 2) {
      return;
    }

    const index = facilityIds.findIndex((f) => f === facilityId);
    const nextFacilityId = next ? cycleNextItemInArray(facilityIds, index) : cyclePreviousItemInArray(facilityIds, index);
    navigate(`/${nextFacilityId}`, { replace: true });
  }

  useKey("Escape", () => dispatch(setFacilityMenuIsActive(true)));
  useKey("Tab", () => cycleSelectedList(true), { aircraftIsSelected: false });
  useKey("`", () => cycleSelectedList(false), { aircraftIsSelected: false });
  useKey("ArrowDown", () => cycleHighlightedAircraft(true), { aircraftIsSelected: false });
  useKey("ArrowUp", () => cycleHighlightedAircraft(false), { aircraftIsSelected: false });
  useKey("Enter", () => highlightedAircraftId && selectAircraft(highlightedAircraftId), { aircraftIsSelected: false });
  useKey("ArrowLeft", () => cycleFacility(false), { ctrl: true, alt: true });
  useKey("ArrowRight", () => cycleFacility(true), { ctrl: true, alt: true });

  return (
    <>
      <Header />
      <Dcl />
      <Footer />
      <FacilityMenu />
    </>
  );
}

export default Tdls;
