import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVnasConfigurationAsync, loginAsync, refreshTokenAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { Environment, VnasConfiguration } from "@vatsim-vnas/js-libs/models/vnas";
import { PositionRole } from "@vatsim-vnas/js-libs/models/vnas/common";
import { SessionInfoDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { tokenHasExpired } from "@vatsim-vnas/js-libs/utils";
import { instanceToInstance } from "class-transformer";
import * as jose from "jose";
import { toast } from "react-toastify";
import { VATSIM_CLIENT_ID, processResponse } from "src/utils";
import { RootState } from "../store";

interface LoginProps {
  code: string;
  redirectUrl: string;
}

function getLocalVatsimToken() {
  const vatsimToken = localStorage.getItem("vatsim-token");
  if (!vatsimToken) {
    return undefined;
  }

  const decodedToken = jose.decodeJwt(vatsimToken);
  if (!tokenHasExpired(decodedToken)) {
    return vatsimToken;
  }

  return undefined;
}

export const getVnasConfiguration = createAsyncThunk("auth/getVnasConfiguration", async () => {
  const res = await getVnasConfigurationAsync();
  return processResponse(res);
});

export const login = createAsyncThunk("auth/login", async (data: LoginProps, thunkApi) => {
  const { environment } = (thunkApi.getState() as RootState).auth;

  if (!environment) {
    throw Error("Environment is undefined");
  }

  const res = await loginAsync(environment.apiBaseUrl, data.code, data.redirectUrl, VATSIM_CLIENT_ID);
  return processResponse(res);
});

export const refreshNasToken = createAsyncThunk("auth/refreshNasToken", async (_, thunkApi) => {
  const { environment, vatsimToken } = (thunkApi.getState() as RootState).auth;

  if (!environment) {
    throw Error("Environment is undefined");
  }

  if (!vatsimToken) {
    throw Error("VATSIM token is undefined");
  }

  const res = await refreshTokenAsync(environment.apiBaseUrl, vatsimToken);
  return processResponse(res);
});

interface AuthState {
  vnasConfiguration?: VnasConfiguration;
  vatsimCode?: string;
  vatsimToken?: string;
  environment?: Environment;
  nasToken?: string;
  session?: SessionInfoDto;
  signalrIsConnected: boolean;
  fsdIsConnected: boolean;
}

const initialState: AuthState = {
  vatsimToken: getLocalVatsimToken(),
  signalrIsConnected: false,
  fsdIsConnected: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getVnasConfiguration.fulfilled, (state, action) => {
      state.vnasConfiguration = action.payload;
      const enabledEnvironments = action.payload.environments.filter((e) => !e.isDisabled);
      const localEnvironment = localStorage.getItem("environment");
      if (localEnvironment) {
        const availableEnvironment = enabledEnvironments.find((e) => e.name === localEnvironment);
        if (availableEnvironment) {
          state.environment = availableEnvironment;
        } else {
          localStorage.removeItem("environment");
          state.environment = enabledEnvironments.at(0);
        }
      } else {
        state.environment = enabledEnvironments.at(0);
      }
    });
    builder.addCase(getVnasConfiguration.rejected, (_, action) => {
      toast.error(`Failed to get vNAS configuration: ${action.error.message}`);
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const { nasToken, vatsimToken } = action.payload;
      state.nasToken = nasToken;
      state.vatsimToken = vatsimToken;
      localStorage.setItem("vatsim-token", vatsimToken);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      localStorage.removeItem("vatsim-token");
      toast.error(`Failed to log in: ${action.error.message}`);
    });
    builder.addCase(refreshNasToken.fulfilled, (state, action) => {
      state.nasToken = action.payload;
    });
    builder.addCase(refreshNasToken.rejected, (state, action) => {
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      localStorage.removeItem("vatsim-token");
      toast.error(`Failed to log in: ${action.error.message}`);
    });
  },
  reducers: {
    setVatsimCode(state, action: { payload: string }) {
      state.vatsimCode = action.payload;
    },
    setEnvironment(state, action: { payload: string }) {
      if (!state.vnasConfiguration) {
        throw Error("Failed to set environment: vNAS configuration is undefined");
      }

      const environment = state.vnasConfiguration.environments.find((e) => e.name === action.payload);
      if (!environment) {
        throw Error(`Failed to set environment: vNAS configuration does not contain environment "${action.payload}"`);
      }

      state.environment = environment;
      localStorage.setItem("environment", action.payload);
    },
    setSession(state, action: { payload: SessionInfoDto }) {
      state.session = action.payload;
      state.signalrIsConnected = true;
      state.fsdIsConnected = true;
    },
    setSessionIsActive(state, action: { payload: boolean }) {
      if (!state.session) {
        throw Error("Failed to set session's active state: session is undefined");
      }
      const newSession = instanceToInstance(state.session);
      newSession.isActive = action.payload;
      state.session = newSession;
    },
    setSignalRConnected(state) {
      state.signalrIsConnected = true;
    },
    setFsdIsConnected(state, action: { payload: boolean }) {
      state.fsdIsConnected = action.payload;
    },
    endSession(state) {
      state.session = undefined;
      state.fsdIsConnected = false;
    },
    logout(state) {
      state.vatsimCode = undefined;
      state.vatsimToken = undefined;
      state.nasToken = undefined;
      state.session = undefined;
      state.signalrIsConnected = false;
      state.fsdIsConnected = false;
      localStorage.removeItem("vatsim-token");
    },
  },
});

export const { setVatsimCode, setEnvironment, setSession, setSessionIsActive, setSignalRConnected, setFsdIsConnected, endSession, logout } =
  authSlice.actions;

export const vnasConfigurationSelector = (state: RootState) => state.auth.vnasConfiguration;
export const vatsimCodeSelector = (state: RootState) => state.auth.vatsimCode;
export const vatsimTokenSelector = (state: RootState) => state.auth.vatsimToken;
export const environmentSelector = (state: RootState) => state.auth.environment;
export const nasTokenSelector = (state: RootState) => state.auth.nasToken;
export const sessionSelector = (state: RootState) => state.auth.session;
export const signalrIsConnectedSelector = (state: RootState) => state.auth.signalrIsConnected;
export const isReadOnlySelector = (state: RootState) =>
  !state.auth.fsdIsConnected || !state.auth.session?.isActive || state.auth.session.role === PositionRole.Observer;

export default authSlice.reducer;
