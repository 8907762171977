import { ClearanceField } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { distinct } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect, useRef } from "react";
import { useTdls } from "src/hooks";
import {
  clearanceSelector,
  selectedAircraftIdSelector,
  selectedClearanceFieldSelector,
  setSelectedClearanceField,
  temporaryDepFreqsSelector,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/tdls/flightPlan";

interface ClearanceFieldSelectProps {
  field: ClearanceField;
}

function ClearanceFieldSelect({ field }: Readonly<ClearanceFieldSelectProps>) {
  const isSelected = useAppSelector(selectedClearanceFieldSelector) === field;
  const clearance = useAppSelector(clearanceSelector)!;
  const temporaryDepFreqs = useAppSelector(temporaryDepFreqsSelector);
  const dispatch = useAppDispatch();
  const selectRef = useRef<HTMLSelectElement>(undefined!);

  const { getFlightPlan, updateClearance, getTransitions, getCurrentConfiguration } = useTdls();
  const configuration = getCurrentConfiguration();
  const flightPlan = getFlightPlan(useAppSelector(selectedAircraftIdSelector)!);
  const isCleared = !!flightPlan.clearance;
  const transitions = getTransitions(clearance.sid);

  useEffect(() => {
    if (selectRef.current && isSelected) {
      selectRef.current.focus();
    }
  }, [isSelected]);

  const getSelectOptions = () => {
    if (isCleared) {
      const option = flightPlan.clearance!.getField(field);
      return <option>{option ?? "- - - -"}</option>;
    }
    switch (field) {
      case ClearanceField.Sid:
        return (
          <>
            <optgroup disabled hidden />
            <option value="-1">- - - -</option>;
            {configuration.sids.map((s) => (
              <option key={s.id} value={s.name}>
                {s.name}
              </option>
            ))}
          </>
        );
      case ClearanceField.Transition:
        return (
          <>
            {!!transitions.length || <option value="-1">- - - -</option>}
            {transitions.map((t) => (
              <option key={t.name} value={t.name}>
                {t.name}
              </option>
            ))}
          </>
        );
      case ClearanceField.DepFreq: {
        const freqs = configuration.getOptions(field).concat(temporaryDepFreqs).filter(distinct);
        return (
          <>
            <option value="-1">- - - -</option>
            {freqs.map((f) => (
              <option key={f} value={f}>
                {f}
              </option>
            ))}
          </>
        );
      }
      default:
        return (
          <>
            <option value="-1">- - - -</option>
            {configuration.getOptions(field).map((o) => (
              <option key={o}>{o}</option>
            ))}
          </>
        );
    }
  };

  return (
    <S.ClearanceFieldSelect
      ref={selectRef}
      $field={field}
      value={clearance.getField(field) ?? "-1"}
      onFocus={() => dispatch(setSelectedClearanceField(field))}
      onChange={(e) => updateClearance(field, e.target.value)}
    >
      {getSelectOptions()}
    </S.ClearanceFieldSelect>
  );
}

export default ClearanceFieldSelect;
