import { Response } from "@vatsim-vnas/js-libs/models/api";
import { routeToDotNotation } from "@vatsim-vnas/js-libs/utils";
import { TdlsFlightPlan } from "src/models";
import { DEBUG_MODE } from "./constants";

export function debugLog<T, S>(message?: T, ...optionalParams: S[]) {
  if (DEBUG_MODE) {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams);
  }
}

export function formatRoute(flightPlan: TdlsFlightPlan) {
  return `${flightPlan.departure}${routeToDotNotation(flightPlan.route)}${flightPlan.destination}/${flightPlan.hoursEnroute
    .toString()
    .padStart(2, "0")}${flightPlan.minutesEnroute.toString().padStart(2, "0")}`.replace(/(\.+)/g, "$1<wbr>");
}

export function processResponse<T>(res: Response<T>): T {
  if (!res.ok) {
    throw Error(res.statusText);
  }
  return res.data!;
}

export function getSavedOption(option: string) {
  const savedOption = localStorage.getItem(option);
  return savedOption ? savedOption === "true" : true;
}
