import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { FallbackProps } from "react-error-boundary";
import * as S from "src/styles/ui";

const BUG_REPORT_DISCORD_FORUM_URL = "https://discord.com/channels/953714419597201408/1022961910045479002";

function ErrorPage({ error }: Readonly<FallbackProps>) {
  return (
    <S.Container>
      <S.ErrorCode data-text="Error">Error</S.ErrorCode>
      <S.ErrorTag>We&apos;ve been navigating by the North Star, which turned out to be the Delta shuttle from LaGuardia...</S.ErrorTag>
      <S.ErrorText>{error.message}</S.ErrorText>
      <S.Button className="discord">
        <S.Icon icon={faDiscord} onClick={() => window.open(BUG_REPORT_DISCORD_FORUM_URL)} />
        Report on Discord
      </S.Button>
    </S.Container>
  );
}

export default ErrorPage;
