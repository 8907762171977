import { useEffect, useRef } from "react";
import { useAppSelector } from "src/redux/hooks";
import { isReadOnlySelector } from "src/redux/slices/authSlice";
import { facilityMenuIsActiveSelector, selectedAircraftIdSelector } from "src/redux/slices/uiSlice";

interface KeyOptions {
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
  isReadOnly?: boolean;
  facilityMenuIsActive?: boolean;
  aircraftIsSelected?: boolean;
}

function optionIsSatisfied(option: boolean | undefined, eventValue: boolean) {
  return option === undefined || option === eventValue;
}

function defaultFalseOptionIsSatisfied(option: boolean | undefined, eventValue: boolean) {
  return (option === undefined && !eventValue) || option === eventValue;
}

const useKey = (key: string, callback: (event: KeyboardEvent) => void, options?: KeyOptions, alternateKeys?: string[]) => {
  const isReadOnly = useAppSelector(isReadOnlySelector);
  const facilityMenuIsActive = useAppSelector(facilityMenuIsActiveSelector);
  const aircraftIsSelected = !!useAppSelector(selectedAircraftIdSelector);

  const isLocked = useRef(false);

  function optionsAreSatisfied(e: KeyboardEvent) {
    if (!options) {
      return !facilityMenuIsActive;
    }
    if (!optionIsSatisfied(options.ctrl, e.ctrlKey)) {
      return false;
    }
    if (!optionIsSatisfied(options.shift, e.shiftKey)) {
      return false;
    }
    if (!optionIsSatisfied(options.alt, e.altKey)) {
      return false;
    }
    if (!optionIsSatisfied(options.isReadOnly, isReadOnly)) {
      return false;
    }
    if (!defaultFalseOptionIsSatisfied(options.facilityMenuIsActive, facilityMenuIsActive)) {
      return false;
    }
    if (!optionIsSatisfied(options.aircraftIsSelected, aircraftIsSelected)) {
      return false;
    }
    return true;
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (!isLocked.current && (e.key === key || alternateKeys?.includes(e.key))) {
      if (optionsAreSatisfied(e)) {
        e.preventDefault();
        callback(e);
      }
    }
  }

  function handleKeyUp() {
    isLocked.current = false;
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [callback]);
};

export default useKey;
